import $ from 'jquery';

var $header = $('.header');
var headerHeight = 85;
var windowHeight = $(window).height();
$(window).on('scroll', function () {
    var windowScrollTop = $(window).scrollTop();

    if (windowScrollTop <= 0) {
        $header.removeClass('scroll');
    } else {
        $header.addClass('scroll');
    }
})

const app = (() => {
	let body;
	let menu;
	let menuItems;
	
	const init = () => {
		body = document.querySelector('body');
		menu = document.querySelector('.menu-icon');
		menuItems = document.querySelectorAll('.main-menu .menu li');
		applyListeners();
	}
	
	const applyListeners = () => {
		menu.addEventListener('click', () => toggleClass(body, 'nav-active'));
	}
	
	const toggleClass = (element, stringClass) => {
		if(element.classList.contains(stringClass))
			element.classList.remove(stringClass);
		else
			element.classList.add(stringClass);
	}
	
	init();
})();