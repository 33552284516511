// Polyfills
import '@babel/polyfill';
import objectFitImages from 'object-fit-images';

// Vendors
import 'intersection-observer';
import './vendor/modernizr-custom';
import 'jquery';
import './vendor/bootstrap.bundle.min.js';

// CSS entry point
import '../scss/main.scss';
import '../../node_modules/swiper/swiper-bundle.min.css';

// Components
import './components/animate';
import './components/menu';
import './components/swiper';

// Init object-fit polyfill
objectFitImages();