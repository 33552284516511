// core version + navigation, pagination modules:
import Swiper, { Navigation, Autoplay } from "swiper";
Swiper.use([Navigation, Autoplay]);

var swiper = new Swiper(".swiperStories", {
    loop: true,
    direction: 'horizontal',
    loopedSlides: 3,
    freeMode: true,
    spaceBetween: 20,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    autoplay: {
        enabled: true,
        delay: 1,
        disableOnInteraction: true
    },
    freeMode: true,
    speed: 5000,
    freeModeMomentum: false,
    autoplayDisableOnInteraction: false,
    preventClicks: false,
    allowTouchMove: false,
});



var swiper = new Swiper(".swiper3Stories .swiper", {
    slidesPerView: "auto",
    spaceBetween: 15,
    loop: true,
    breakpoints: {
        992: {
            slidesPerView: 3,
            loop: true,
            spaceBetween: 10,
        },
    },
    navigation: {
        nextEl: ".swiper3Stories .swiper-button-next",
        prevEl: ".swiper3Stories .swiper-button-prev",
    },
});

var swiper = new Swiper(".swiper3News .swiper", {
    slidesPerView: "auto",
    spaceBetween: 15,
    loop: true,
    breakpoints: {
        992: {
            slidesPerView: 3,
        },
    },
    navigation: {
        nextEl: ".swiper3News .swiper-button-next",
        prevEl: ".swiper3News .swiper-button-prev",
    },
});

